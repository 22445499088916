// src/App.js
import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';
import React from 'react';
import KYC from './KYC';
import BYN from './BYN';
import './App.css';

function App() {
  return (
    <Router>
      <div className="app-container">
        <header className="app-header">
          <nav>
            <span className="nav-link disabled-link" title="Coming Soon!">
              Hot Wallets
            </span>
            <Link to="/build-device" className="nav-link">
              Cold Wallets
            </Link>
          </nav>
        </header>
        <main>
          <Switch>
            <Route path="/kyc-form" component={KYC} />
            <Route path="/build-device" component={BYN} />
            <Route path="/" exact component={BYN} /> {/* Default Route */}
          </Switch>
        </main>
      </div>
    </Router>
  );
}

export default App;
