import React, { useState } from 'react';
import './KYC.css';

const KYC = () => {
  const [personalInfo, setPersonalInfo] = useState({
    firstName: '',
    lastName: '',
    dob: '',
    gender: '',
  });

  const [contactInfo, setContactInfo] = useState({
    email: '',
    phone: '',
    address: '',
  });

  const [identification, setIdentification] = useState({
    idType: '',
    idNumber: '',
    idCountry: '',
    idDocument: null,
  });

  const [financialInfo, setFinancialInfo] = useState({
    incomeLevel: '',
    employmentStatus: '',
    sourceOfFunds: '',
  });

  const handleChange = (section, field, value) => {
    switch (section) {
      case 'personalInfo':
        setPersonalInfo(prevState => ({ ...prevState, [field]: value }));
        break;
      case 'contactInfo':
        setContactInfo(prevState => ({ ...prevState, [field]: value }));
        break;
      case 'identification':
        setIdentification(prevState => ({ ...prevState, [field]: value }));
        break;
      case 'financialInfo':
        setFinancialInfo(prevState => ({ ...prevState, [field]: value }));
        break;
      default:
        break;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Submit functionality will be implemented in the future
  };

  return (
    <form className="kyc-form" onSubmit={handleSubmit}>
      <div className="form-section">
        <h2>Spending Wallet (Hot 🥵 Hard & Soft Storage)</h2>
        <div className="input-group">
          <label>First Name</label>
          <input
            type="text"
            placeholder="First Name"
            value={personalInfo.firstName}
            onChange={(e) => handleChange('personalInfo', 'firstName', e.target.value)}
          />
        </div>
        <div className="input-group">
          <label>Last Name</label>
          <input
            type="text"
            placeholder="Last Name"
            value={personalInfo.lastName}
            onChange={(e) => handleChange('personalInfo', 'lastName', e.target.value)}
          />
        </div>
        <div className="input-group">
          <label>Date of Birth</label>
          <input
            type="date"
            placeholder="Date of Birth"
            value={personalInfo.dob}
            onChange={(e) => handleChange('personalInfo', 'dob', e.target.value)}
          />
        </div>
        <div className="input-group">
          <label>Gender</label>
          <select
            value={personalInfo.gender}
            onChange={(e) => handleChange('personalInfo', 'gender', e.target.value)}
          >
            <option value="">Select Gender</option>
            <option value="male">Male</option>
            <option value="female">Female</option>
            <option value="other">Other</option>
          </select>
        </div>
      </div>

      <div className="form-section">
        <h2>Contact Information</h2>
        <div className="input-group">
          <label>Email</label>
          <input
            type="email"
            placeholder="Email"
            value={contactInfo.email}
            onChange={(e) => handleChange('contactInfo', 'email', e.target.value)}
          />
        </div>
        <div className="input-group">
          <label>Phone Number</label>
          <input
            type="tel"
            placeholder="Phone Number"
            value={contactInfo.phone}
            onChange={(e) => handleChange('contactInfo', 'phone', e.target.value)}
          />
        </div>
        <div className="input-group">
          <label>Address</label>
          <input
            type="text"
            placeholder="Address"
            value={contactInfo.address}
            onChange={(e) => handleChange('contactInfo', 'address', e.target.value)}
          />
        </div>
      </div>

      <div className="form-section">
        <h2>Identification</h2>
        <div className="input-group">
          <label>ID Type</label>
          <select
            value={identification.idType}
            onChange={(e) => handleChange('identification', 'idType', e.target.value)}
          >
            <option value="">Select ID Type</option>
            <option value="passport">Passport</option>
            <option value="driversLicense">Driver's License</option>
            <option value="nationalId">National ID</option>
          </select>
        </div>
        <div className="input-group">
          <label>ID Number</label>
          <input
            type="text"
            placeholder="ID Number"
            value={identification.idNumber}
            onChange={(e) => handleChange('identification', 'idNumber', e.target.value)}
          />
        </div>
        <div className="input-group">
          <label>ID Issuing Country</label>
          <input
            type="text"
            placeholder="ID Issuing Country"
            value={identification.idCountry}
            onChange={(e) => handleChange('identification', 'idCountry', e.target.value)}
          />
        </div>
        <div className="input-group">
          <label>Upload ID Document</label>
          <input
            type="file"
            onChange={(e) => handleChange('identification', 'idDocument', e.target.files[0])}
          />
        </div>
      </div>

      <div className="form-section">
        <h2>Financial Information</h2>
        <div className="input-group">
          <label>Income Level</label>
          <input
            type="text"
            placeholder="Income Level"
            value={financialInfo.incomeLevel}
            onChange={(e) => handleChange('financialInfo', 'incomeLevel', e.target.value)}
          />
        </div>
        <div className="input-group">
          <label>Employment Status</label>
          <input
            type="text"
            placeholder="Employment Status"
            value={financialInfo.employmentStatus}
            onChange={(e) => handleChange('financialInfo', 'employmentStatus', e.target.value)}
          />
        </div>
        <div className="input-group">
          <label>Source of Funds</label>
          <input
            type="text"
            placeholder="Source of Funds"
            value={financialInfo.sourceOfFunds}
            onChange={(e) => handleChange('financialInfo', 'sourceOfFunds', e.target.value)}
          />
        </div>
      </div>

      <button type="submit" className="submit-btn" disabled>
        Coming Soon!
      </button>
    </form>
  );
}

export default KYC;
