import React, { useState, useEffect, Component } from 'react';
import './BYN.css';

import {
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
  injectStripe,
  StripeProvider,
  Elements
} from 'react-stripe-elements';

class _SplitFieldsForm extends Component {
    constructor(props) {
        super(props);
    
        // Create refs for the card elements
        this.cardNumberRef = React.createRef();
        this.cardExpiryRef = React.createRef();
        this.cardCvcRef = React.createRef();
    }
    state = {
        errorMessage: '',
        cardDetails: {
        complete: false
        },
        totalPrice: 0,
        load: false
    };

    handleChange = (e) => {
        this.setState({
        cardDetails: {
            ...this.state.cardDetails,
            [e.elementType]: e.complete ? e.value : '',
            [e.brand]: e.complete
        },
        });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const {
            device,
            totalPrice,
            setReceiptData,
            setIsPaymentOpen,
            setIsReceiptOpen,
        } = this.props;
        this.setState({ load: true })
        if (this.props.stripe) {
            this.props.stripe.createToken()
            .then(token => { 
                this.props.stripe.createPaymentMethod({
                    type: 'card',
                    card: this.cardNumberRef.current._element,
                    billing_details: {
                        name: this.props.kycInfo.name
                    },
                }).then((result) => {
                    const data = {
                        token,
                        device,
                        kyc: this.props.kycInfo,
                        card: result.paymentMethod,
                        amount: totalPrice,  // Amount in cents
                        description: `Build Your Node - ${device.device}`,
                    };

                    // Fetch POST request
                    fetch('https://api.muvor.com/api/auth/stripe/byn', {
                    // fetch('http://192.168.0.199:9000/api/auth/stripe/byn', {
                        method: 'POST', // Specify the request method
                        headers: {
                            'Content-Type': 'application/json', // Specify the content type as JSON
                        },
                        body: JSON.stringify(data), // Convert the data to JSON and send it in the body
                    }).then(response => {
                        if (!response.ok) {
                            throw new Error('Network response was not ok');
                        };

                        return response.json(); // Parse the JSON from the response
                    }).then(result => {                        
                        setReceiptData(result.data);
                        setIsPaymentOpen(false); // Close payment modal
                        setIsReceiptOpen(true); // Open receipt modal
                    }).catch(error => {
                        console.error('Error:', error); // Handle any errors
                    });
                }).catch((err) => {window.alert(`${err}: FAILED. \n\n Click next to continue`);window.location.reload();});
            }).catch(err => window.alert('Token Creation Failed: ', err))
        } else {
            console.log("Stripe.js hasn't loaded yet.");
        }
    };

    handleError = ({error}) => {
        if (error) {
        this.setState({errorMessage: error.message});
        }
    };

    get invalid() {
        const {
        loading
        } = this.props
        const {
        cardDetails,
        load
        } = this.state

        return !(
        !loading
        && cardDetails.undefined
        && !load
        )
    }
    
    render() {
        return (
            <div className="checkoutForm">
                <form>
                    <label>
                        Card details
                        <CardNumberElement ref={this.cardNumberRef} onChange={this.handleChange} />
                    </label>
                    <label>
                        Expiration date
                        <CardExpiryElement ref={this.cardExpiryRef} onChange={this.handleChange} />
                    </label>
                    <label>
                        CVC
                        <CardCVCElement ref={this.cardCvcRef} onChange={this.handleChange} />
                    </label>
                </form>
                <div className="split">
                    <button className="b1" onClick={this.props.onCancel}>Cancel</button>
                    <button className="b2" onClick={this.handleSubmit}>
                        Pay ${this.props.totalPrice.toFixed(2)}
                    </button>
                </div>
            </div>
        );
    }
}

const SplitFieldsForm = injectStripe(_SplitFieldsForm);

function BYN() {
  const [totalPrice, setTotalPrice] = useState(0);
  const [deviceComplete, setDeviceComplete] = useState({});
  const [selectedDeviceType, setSelectedDeviceType] = useState('');
  const [selectedCPU, setSelectedCPU] = useState('');
  const [selectedGPU, setSelectedGPU] = useState('');
  const [selectedRAM, setSelectedRAM] = useState('');
  const [selectedROM, setSelectedROM] = useState('');
  const [selectedNIC, setSelectedNIC] = useState('');
  const [selectedBrand, setSelectedBrand] = useState('');
  const [selectedOS, setSelectedOS] = useState(''); // OS Selection state
  const [selectedPeripherals, setSelectedPeripherals] = useState([]);
  const [selectedAccessories, setSelectedAccessories] = useState([]);
  const [selectedDualCPUs, setSelectedDualCPUs] = useState([]);
  const [selectedDualGPUs, setSelectedDualGPUs] = useState([]);
  const [isRefurbished, setIsRefurbished] = useState(false);
  const [openAccordion, setOpenAccordion] = useState(null);
  const [isPaymentOpen, setIsPaymentOpen] = useState(false); // Modal state for payment
  const [isReceiptOpen, setIsReceiptOpen] = useState(false); // Modal state for receipt
  const [receiptData, setReceiptData] = useState(null); // Store receipt data
  const [kycInfo, setKycInfo] = useState({ name: '', email: '', address: '' });
  const [isKycOpen, setIsKycOpen] = useState(false); // Modal state for KYC

  const allOptions = {
    device: [
      { name: 'Laptop', price: 500 },
      { name: 'PC', price: 400 },
      { name: 'All-in-One', price: 450 },
      { name: 'Server', price: 1000 },
      { name: 'USB', price: 50 },
      { name: 'Chromebook', price: 300 },
    ],
    brand: [
      { name: 'Dell', price: 100 },
      { name: 'HP', price: 100 },
      { name: 'Lenovo', price: 120 },
      { name: 'Apple', price: 200 },
      { name: 'Acer', price: 100 },
      { name: 'Asus', price: 120 },
      { name: 'Google', price: 100 },
    ],
    CPU: [
      { name: 'Intel i5', price: 350 },
      { name: 'Intel i7', price: 450 },
      { name: 'Intel i9', price: 650 },
      { name: 'AMD Ryzen 5', price: 250 },
      { name: 'AMD Ryzen 7', price: 350 },
      { name: 'AMD Ryzen 9', price: 550 },
      { name: 'Apple M1', price: 700 },      
      { name: 'Intel Xeon Platinum', price: 5000 },
      { name: 'Intel Xeon Gold', price: 2000 },
      { name: 'AMD EPYC', price: 3000 },
      { name: 'AMD Threadripper', price: 5000 },
    ],
    dualCpu: [
      { name: 'Intel Xeon Platinum', price: 5000 },
      { name: 'Intel Xeon Gold', price: 2000 },
      { name: 'AMD EPYC', price: 3000 },
      { name: 'AMD Threadripper', price: 5000 },
    ],
    GPU: [
        { name: 'Apple M1', price: 250 },
        { name: 'Intel Integrated', price: 100 },
        { name: 'NVIDIA GTX 1650', price: 150 },
        { name: 'AMD Radeon RX 5500', price: 200 },
        { name: 'NVIDIA RTX 3060', price: 300 },
        { name: 'NVIDIA Quadro RTX 8000', price: 2000 },
        { name: 'AMD Radeon Pro VII', price: 1500 },
        { name: 'NVIDIA Tesla V100', price: 3000 },
    ],
    dualGpu: [
      { name: 'NVIDIA Quadro RTX 8000', price: 2000 },
      { name: 'AMD Radeon Pro VII', price: 1500 },
      { name: 'NVIDIA Tesla V100', price: 3000 },
    ],
    RAM: [
      { name: '4GB', price: 30 },
      { name: '8GB', price: 50 },
      { name: '16GB', price: 100 },
      { name: '32GB', price: 200 },
      { name: '64GB', price: 400 },
      { name: '128GB', price: 800 },
      { name: '256GB', price: 1500 },
      { name: '512GB', price: 3000 },
      { name: '1TB', price: 5000 },
    ],
    ROM: [
      { name: '32GB eMMC', price: 30 },
      { name: '64GB eMMC', price: 50 },
      { name: '256GB SSD', price: 50 },
      { name: '512GB SSD', price: 100 },
      { name: '1TB SSD', price: 200 },
      { name: '4TB SSD', price: 600 },
      { name: '8TB SSD', price: 1000 },
      { name: '16TB SSD', price: 2000 },
      { name: '32TB SSD', price: 4000 },
      { name: '64TB SSD', price: 7500 },
    ],
    NIC: [
      { name: 'Intel Ethernet I225-V', price: 50 },
      { name: 'Realtek RTL8125B', price: 40 },
      { name: 'Aquantia AQC107', price: 70 },
      { name: 'Broadcom BCM5720', price: 60 },
      { name: 'Mellanox ConnectX-4', price: 100 },
      { name: 'TP-Link TX-401', price: 150 },
    ],
    peripherals: [
      { name: 'Mouse', price: 20 },
      { name: 'Keyboard', price: 30 },
      { name: 'Monitor', price: 150 },
    ],
    accessories: [
      { name: 'Laptop Bag', price: 50 },
      { name: 'External HDD', price: 80 },
      { name: 'Cooling Pad', price: 40 },
      { name: 'Water Cooling', price: 500 },
    ],
    OS: [
      { name: 'Debian', price: 0 },
      { name: 'Ubuntu', price: 0 },
      { name: 'Kali Linux', price: 0 },
      { name: 'Linux Mint', price: 0 },
      { name: 'Elementary OS', price: 0 },
      { name: 'Zorin OS', price: 0 },
      { name: 'Parrot OS', price: 0 },
      { name: 'MX Linux', price: 0 },
      { name: 'Tails', price: 0 },
      { name: 'Deepin', price: 0 },
      { name: 'Pop!_OS', price: 0 },
    ],
  };

  useEffect(() => {
      if (totalPrice < 0) {
      setTotalPrice(0);
      }
  }, [totalPrice]);

  const onCheckout = () => {
      setIsKycOpen(true);
  };

  const handleSelectionChange = (event, category) => {
    const selectedOption = allOptions[category].find(option => option.name === event.target.value);
    const priceDifference = selectedOption ? selectedOption.price : 0;

    setTotalPrice(prevTotal => prevTotal + priceDifference);

    if (category === 'device') {
      setSelectedDeviceType(event.target.value);
      setSelectedBrand('');
      setSelectedDualCPUs([]);
      setSelectedDualGPUs([]);
      setIsRefurbished(false);
    }

    if (category === 'brand') {
      setSelectedBrand(event.target.value);
    }

    if (category === 'CPU') {
      setSelectedCPU(event.target.value);
    }

    if (category === 'GPU') {
        setSelectedGPU(event.target.value);
    }

    if (category === 'RAM') {
        setSelectedRAM(event.target.value);
    }

    if (category === 'ROM') {
        setSelectedROM(event.target.value);
    }

    if (category === 'NIC') {
        setSelectedNIC(event.target.value);
    }

    if (category === 'OS') {
        setSelectedOS(event.target.value);
    }
  };

  const handleCheckboxChange = (event, category) => {
    const selectedItem = allOptions[category].find(option => option.name === event.target.value);
    const isChecked = event.target.checked;

    if (isChecked) {
      setTotalPrice(prevTotal => prevTotal + selectedItem.price);
      if (category === 'peripherals') {
        setSelectedPeripherals([...selectedPeripherals, selectedItem.name]);
      } else if (category === 'accessories') {
        setSelectedAccessories([...selectedAccessories, selectedItem.name]);
      } else if (category === 'dualCpu') {
        setSelectedDualCPUs([...selectedDualCPUs, selectedItem.name]);
      } else if (category === 'dualGpu') {
        setSelectedDualGPUs([...selectedDualGPUs, selectedItem.name]);
      }
    } else {
      setTotalPrice(prevTotal => prevTotal - selectedItem.price);
      if (category === 'peripherals') {
        setSelectedPeripherals(selectedPeripherals.filter(item => item !== selectedItem.name));
      } else if (category === 'accessories') {
        setSelectedAccessories(selectedAccessories.filter(item => item !== selectedItem.name));
      } else if (category === 'dualCpu') {
        setSelectedDualCPUs(selectedDualCPUs.filter(item => item !== selectedItem.name));
      } else if (category === 'dualGpu') {
        setSelectedDualGPUs(selectedDualGPUs.filter(item => item !== selectedItem.name));
      }
    }
  };

  const toggleAccordion = (section) => {
    setOpenAccordion(openAccordion === section ? null : section);
  };

  const handleRefurbishedChange = (event) => {
    const isChecked = event.target.checked;
    const priceAdjustment = isChecked ? -300 : 300;

    setIsRefurbished(isChecked);
    setTotalPrice(prevTotal => {
      const newTotal = prevTotal + priceAdjustment;
      return newTotal < 0 ? 0 : newTotal;
    });
  };

  const handleKycChange = (e) => {
    setKycInfo({
      ...kycInfo,
      [e.target.name]: e.target.value,
    });
  };

  const handleKycSubmit = () => {
    // Construct the device object with all selected options
    const device = {
        device: selectedDeviceType,
        brand: selectedBrand,
        CPU: selectedCPU,
        GPU: selectedGPU,
        RAM: selectedRAM,
        ROM: selectedROM,
        NIC: selectedNIC,
        OS: selectedOS,
        peripherals: selectedPeripherals,
        accessories: selectedAccessories,
        dualCPUs: selectedDualCPUs,
        dualGPUs: selectedDualGPUs,
        isRefurbished: isRefurbished,
        totalPrice: totalPrice,
    };
    setDeviceComplete(device)
    setIsKycOpen(false); // Close KYC modal
    setIsPaymentOpen(true); // Open payment modal when checkout is clicked
  };
  
  const handleClose = () => {
    setIsKycOpen(false); // Close payment modal
  };

  const handleCancel = () => {
    setIsPaymentOpen(false); // Close payment modal
  };

  const getFilteredOptions = (category) => {
    switch (category) {
      case 'RAM':
        if (selectedDeviceType === 'Server') {
          return allOptions.RAM.filter(option => 
            option.name === '64GB' 
            || option.name === '128GB' 
            || option.name === '256GB' 
            || option.name === '512GB' 
            || option.name === '1TB');
        }
        if (selectedDeviceType === 'Chromebook') {
          return allOptions.RAM.filter(option => 
            option.name === '4GB' 
            || option.name === '8GB');
        }
        return allOptions.RAM;
      case 'ROM':
        if (selectedDeviceType === 'USB') {
          return allOptions.ROM.filter(option => option.name.includes('eMMC') || option.name.includes('1TB'));
        }
        if (selectedDeviceType === 'Server') {
          return allOptions.ROM.filter(option => 
            option.name === '4TB SSD'
            || option.name === '8TB SSD'
            || option.name === '16TB SSD'
            || option.name === '32TB SSD');
        }
        if (selectedDeviceType === 'Chromebook') {
          return allOptions.ROM.filter(option => option.name === '32GB eMMC' || option.name === '64GB eMMC' || option.name === '1TB SSD');
        }
        return allOptions.ROM;
      case 'CPU':
        if (selectedBrand === 'Apple') {
          return allOptions.CPU.filter(option => option.name === 'Apple M1');
        }
        if (selectedDeviceType === 'Chromebook') {
          return allOptions.CPU.filter(option => option.name === 'Intel Celeron' || option.name === 'MediaTek');
        }
        return allOptions.CPU;
      case 'GPU':
        if (selectedBrand === 'Apple') {
          return allOptions.GPU.filter(option => option.name === 'Apple M1');
        }
        return allOptions.GPU;
      case 'NIC':
        return allOptions.NIC;
      case 'OS':
        return allOptions.OS;
      default:
        return allOptions[category];
    }
  };

  const isDisabled = (category) => {
    switch (category) {
      case 'brand':
      case 'OS':
      case 'CPU':
      case 'GPU':
      case 'RAM':
      case 'NIC':
      case 'dualCpu':
      case 'dualGpu':
        return selectedDeviceType === 'USB';
      case 'refurbished':
        return !selectedDeviceType;
      default:
        return false;
    }
  };

  return (
    <div className="build-device-container">
      <h1>Build Your Node (Cold 🥶 Hard & Soft Storage)</h1>
      <div className="selectors">
        {['device', 'brand', 'OS', 'CPU', 'GPU', 'RAM', 'ROM', 'NIC'].map((category) => (
          <div key={category} className="selector">
            <label>{category.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}:</label>
            <select
              onChange={(e) => handleSelectionChange(e, category)}
              disabled={isDisabled(category)}
            >
              <option value="">Select {category}</option>
              {getFilteredOptions(category).map((option) => (
                <option key={option.name} value={option.name}>
                  {option.name} (+${option.price})
                </option>
              ))}
            </select>
          </div>
        ))}

        {selectedDeviceType === 'Server' && (
          <>
            <div className="dual-selection">
              <label>Select up to 2 CPUs:</label>
              {allOptions.dualCpu.map((CPU) => (
                <div key={CPU.name} className="checkbox">
                  <label>
                    <input
                      type="checkbox"
                      value={CPU.name}
                      onChange={(e) => handleCheckboxChange(e, 'dualCpu')}
                      disabled={selectedDualCPUs.length >= 2 && !selectedDualCPUs.includes(CPU.name)}
                    />
                    {CPU.name} (+${CPU.price})
                  </label>
                </div>
              ))}
            </div>

            <div className="dual-selection">
              <label>Select up to 2 GPUs:</label>
              {allOptions.dualGpu.map((GPU) => (
                <div key={GPU.name} className="checkbox">
                  <label>
                    <input
                      type="checkbox"
                      value={GPU.name}
                      onChange={(e) => handleCheckboxChange(e, 'dualGpu')}
                      disabled={selectedDualGPUs.length >= 2 && !selectedDualGPUs.includes(GPU.name)}
                    />
                    {GPU.name} (+${GPU.price})
                  </label>
                </div>
              ))}
            </div>
          </>
        )}

        <div className="accordion">
          <div className="accordion-section">
            <button onClick={() => toggleAccordion('peripherals')}>
              Peripherals {openAccordion === 'peripherals' ? '-' : '+'}
            </button>
            {openAccordion === 'peripherals' && (
              <div className="accordion-content">
                {allOptions.peripherals.map((item) => (
                  <div key={item.name} className="checkbox">
                    <label>
                      <input
                        type="checkbox"
                        value={item.name}
                        onChange={(e) => handleCheckboxChange(e, 'peripherals')}
                      />
                      {item.name} (+${item.price})
                    </label>
                  </div>
                ))}
              </div>
            )}
          </div>

          <div className="accordion-section">
            <button onClick={() => toggleAccordion('accessories')}>
              Accessories {openAccordion === 'accessories' ? '-' : '+'}
            </button>
            {openAccordion === 'accessories' && (
              <div className="accordion-content">
                {allOptions.accessories.map((item) => (
                  <div key={item.name} className="checkbox">
                    <label>
                      <input
                        type="checkbox"
                        value={item.name}
                        onChange={(e) => handleCheckboxChange(e, 'accessories')}
                      />
                      {item.name} (+${item.price})
                    </label>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>

        <div className="refurbished">
          <label>
            <input
              type="checkbox"
              onChange={handleRefurbishedChange}
              disabled={isDisabled('refurbished')}
            />
            Refurbished (-$300)
          </label>
        </div>
      </div>
      
      <div className="total">
        <h2>Total Price: ${totalPrice}</h2>
        <button onClick={onCheckout} className="checkout-button">Checkout</button>
        <p className="flashing-text">Ships in 1-2 Months!</p>
      </div>

      {isKycOpen && (
        <div className="modal">
            <div className="modal-content">
            <h2>Enter Your Information</h2>
            <form>
                <label>
                Name:
                <input
                    type="text"
                    name="name"
                    value={kycInfo.name}
                    onChange={handleKycChange}
                />
                </label>
                <label>
                Email:
                <input
                    type="email"
                    name="email"
                    value={kycInfo.email}
                    onChange={handleKycChange}
                />
                </label>
                <label>
                Address:
                <input
                    type="text"
                    name="address"
                    value={kycInfo.address}
                    onChange={handleKycChange}
                />
                </label>
            </form>
            <div className="split">
                <button className="b1" onClick={handleClose}>Cancel</button>
                <button className="b2" onClick={handleKycSubmit}>
                Proceed to Payment
                </button>
            </div>
            </div>
        </div>
        )}

      {isPaymentOpen && (
        <div className="modal">
          <div className="modal-content">
            <h2>Enter Payment Details</h2>
            {
                // <StripeProvider apiKey="pk_test_zg1kvZwcfPwJc35UXpGr6xMy00Rpleq9Ll">
            }
            <StripeProvider apiKey="pk_live_HVSDLMk8WKyXJpbT1GTTKefb00YvpebVqb"> 
                <Elements>
                    <SplitFieldsForm 
                        totalPrice={totalPrice} 
                        device={deviceComplete} 
                        setReceiptData={setReceiptData}
                        setIsPaymentOpen={setIsPaymentOpen}
                        setIsReceiptOpen={setIsReceiptOpen}
                        onCancel={handleCancel}
                        kycInfo={kycInfo}
                    />
                </Elements>
            </StripeProvider>
          </div>
        </div>
      )}

      {isReceiptOpen && (
        <div className="modal">
          <div className="modal-content">
            <h2>Receipt</h2>
            <p><strong>Description:</strong> {receiptData.description}</p>
            <p><strong>Amount:</strong> ${receiptData.amount.toFixed(2)}</p>
            <p><strong>Transaction ID:</strong> {receiptData.identifier}</p>
            <button onClick={() => setIsReceiptOpen(false)}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default BYN;
